import React from "react";
import { useIntl, Link } from "gatsby-plugin-intl";
import { Nav } from './nav';

type HeroProps = {
	nav?: boolean,
	link: string,
};

export const Hero: React.FC<HeroProps> = ({ link, nav }) => {
  const intl = useIntl();

  return (
    <div className="hero" id="top">
      <h3>Avond Cock-up</h3>
      <h2>{intl.formatMessage({ id: "ode" })}</h2>
      <div className="lang">
				<Link to={link} language="fr">Français</Link> | <Link to={link} language="nl">Nederlands</Link> | <Link to={link} language="en">English</Link>
      </div>
			{ nav ? <Nav /> : null }
    </div>
  )
};
