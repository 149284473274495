import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl";

import { Header } from "./header";
import { Nav } from "./nav";
import { Hero } from "./hero";
import "./defaults.css"
import "./design.css"

type LayoutProps = {
	path: string,
	nav?: boolean,
	children?: React.ReactNode,
};

export const Layout: React.FC<LayoutProps> = ({
	nav = true,
	path,
	children,
}) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

	const link = path ? '/' + path.split('/').slice(2).join('/') : '/';

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
			<Hero link={link} nav={nav} />
      <main>{children}</main>
			<Nav link={link} />
			<div className="share">
				<div className="share-label">{intl.formatMessage({ id: "Share on" })}</div>
				<div className="s9-widget-wrapper"></div>
			</div>
      <footer
        style={{
          marginTop: `2rem`,
        }}
      >
        <div className="colofon">
          <div>{intl.formatMessage({
            id: 'colofon1',
            defaultMessage: '{name}',
          }, {
            name: 'Steven Wittens',
          })}</div>
          <div>{intl.formatMessage({ id: 'colofon2' })} <a href="mailto:avondcockup@pm.me">avondcockup@pm.me</a></div>
        </div>
        <div>© {new Date().getFullYear()}</div>
      </footer>
    </>
  )
}
