import React from "react";
import { useIntl, Link } from "gatsby-plugin-intl";

export const Nav: React.FC = () => {
  const intl = useIntl();

  return (
    <div className="nav">
			<h3 className="category-header">{intl.formatMessage({ id: "Timeline" })}</h3>
      <div className="categories">
        <Link to="/early/">{intl.formatMessage({ id: "The Early Days" })}</Link>
				<Link to="/curfew/">{intl.formatMessage({ id: "The Curfew" })}</Link>
				<Link to="/coronapass/">{intl.formatMessage({ id: "The Corona Pass" })}</Link>
      </div>
			<h3 className="category-header">{intl.formatMessage({ id: "Articles" })}</h3>
      <div className="articles">
				<Link to="/article/a-tale-of-two-protests/">{intl.formatMessage({ id: "A Tale of Two Protests" })}</Link>
      </div>
    </div>
  )
};
