import React from "react";
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

type HeaderProps = {
  siteTitle: string,
};

export const Header: React.FC<HeaderProps> = ({ siteTitle }) => {
  const intl = useIntl();
  const { locale } = intl;
  const slogan = intl.formatMessage({ id: 'slogan' });

  return (
    <header>
      <div className="paleis"></div>
      <div className={`slogan slogan-${locale}`} title={slogan}></div>
    </header>
  );
};
